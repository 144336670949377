<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-card :loading="loading">
      <v-tabs v-model="tab">
        <v-tab>{{ $store.getters.translate("edit") }}</v-tab>
        <v-tab @click="preview('nl')">{{ $store.getters.translate("preview_nl") }}</v-tab>
        <v-tab @click="preview('en')">{{ $store.getters.translate("preview_en") }}</v-tab>
        <v-toolbar dense flat>
          <v-spacer/>
          <v-btn v-if="canDelete" @click="archive" text small color="secondary">
            <v-icon color="error">mdi-archive</v-icon>
          </v-btn>
          <v-btn v-if="canEdit" text small @click="save" color="primary">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <v-tab-item>
          <v-row>
            <v-col cols="2">
              <v-card flat>
                <v-card-text>
                  <template v-for="field in usual_fields">
                    <base-field
                        :key="field.name"
                        :value="record[field.name]"
                        :field="field"
                        @change="usualFieldDataChanged"/>
                  </template>
                  <v-row class="mb-5">
                    <v-col>
                      <v-text-field
                        dense
                        class="ma-0"
                        :label="$store.getters.translate('margin_top')"
                        v-model="record.margin_top"
                        suffix="mm"/>
                    </v-col>
                    <v-col>
                      <v-text-field
                        dense
                        class="ma-0"
                        :label="$store.getters.translate('margin_bottom')"
                        v-model="record.margin_bottom"
                        suffix="mm"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        class="ma-0"
                        :label="$store.getters.translate('margin_left')"
                        v-model="record.margin_left"
                        suffix="mm"/>
                    </v-col>
                    <v-col>
                      <v-text-field
                        dense
                        class="ma-0"
                        :label="$store.getters.translate('margin_right')"
                        v-model="record.margin_right"
                        suffix="mm"/>
                    </v-col>
                  </v-row>
                  <v-row class="mb-5">
                    <v-col>
                      <v-btn text color="primary" @click="showHeader" block>{{ $store.getters.translate("header") }}</v-btn>
                      <BaseDraggableModal
                          name="modal_header"
                          max-height="80vh"
                          width="70vw"
                          :fullscreen="$vuetify.breakpoint.xsOnly"
                          ref="modal_header">
                        <v-card>
                          <BaseModal>
                            <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
                              <v-btn icon @click.stop="hideHeader">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{ $store.getters.translate("header") }}</v-toolbar-title>
                            <v-spacer/>
                              <v-btn @click.stop="save" text small>
                              <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                          </v-toolbar>
                            <div slot="content">
                              <v-divider/>
                              <v-card-text>
                                <v-tabs>
                                  <v-tab>{{ $store.getters.translate("header_nl") }}</v-tab>
                                  <v-tab>{{ $store.getters.translate("header_en") }}</v-tab>
                                  <v-tab-item>
                                    <base-editor v-model="record.header_nl" height="500"/>
                                  </v-tab-item>
                                  <v-tab-item>
                                    <base-editor v-model="record.header_en" height="500"/>
                                  </v-tab-item>
                                </v-tabs>
                              </v-card-text>
                            </div>
                          </BaseModal>
                        </v-card>
                      </BaseDraggableModal>
                    </v-col>
                  </v-row>
                  <v-row class="mb-5">
                    <v-col>
                      <v-btn text color="primary" @click="showFooter" block>{{ $store.getters.translate("footer") }}</v-btn>
                      <BaseDraggableModal
                          name="modal_footer"
                          max-height="80vh"
                          width="70vw"
                          :fullscreen="$vuetify.breakpoint.xsOnly"
                          ref="modal_footer">
                        <v-card>
                          <BaseModal>
                            <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
                              <v-btn icon @click.stop="hideFooter">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{ $store.getters.translate("footer") }}</v-toolbar-title>
                            <v-spacer/>
                              <v-btn @click.stop="save" text small>
                              <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                          </v-toolbar>
                            <div slot="content">
                              <v-divider/>
                              <v-card-text>
                                <v-tabs>
                                  <v-tab>{{ $store.getters.translate("footer_nl") }}</v-tab>
                                  <v-tab>{{ $store.getters.translate("footer_en") }}</v-tab>
                                  <v-tab-item>
                                    <base-editor v-model="record.footer_nl" height="500"/>
                                  </v-tab-item>
                                  <v-tab-item>
                                    <base-editor v-model="record.footer_en" height="500"/>
                                  </v-tab-item>
                                </v-tabs>
                              </v-card-text>
                            </div>
                          </BaseModal>
                        </v-card>
                      </BaseDraggableModal>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <span class="mb-5">{{ $store.getters.translate("background_image") }}</span>
                      <v-file-input
                        v-if="!record.background_image"
                        accept="image/*"
                        :label="$store.getters.translate('background_image')"
                        show-size
                        @change="saveImage"
                        v-model="file"/>
                      <div class="text-center" v-else>
                        <v-img :src="record.background_image.full_path" max-height="150" contain style="border: 1px solid lightgrey"/>
                        <v-btn @click="deleteImage" text color="red" class="mt-2">
                          <v-icon color="error">mdi-trash-can</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <base-log-field :model_type="page.model" :model_id="record.id"/>
                      <base-tags v-if="tags_required" v-model="record.tags" :module_name="page.name"/>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-divider vertical/>
            <v-col>
              <v-card flat>
                <v-card-text>
                  <v-tabs>
                    <v-tab @click="setLanguage('nl')">{{ $store.getters.translate("nl") }}</v-tab>
                    <v-tab @click="setLanguage('en')">{{ $store.getters.translate("en") }}</v-tab>
                    <v-tab-item>
                      <base-editor class="mb-5" ref="rte_main_nl" v-model="record.html_nl" :margins="margins" height="1200"/>
                    </v-tab-item>
                    <v-tab-item>
                      <base-editor class="mb-5" ref="rte_main_en" v-model="record.html_en" :margins="margins" height="1200"/>
                    </v-tab-item>
                  </v-tabs>
                </v-card-text>
              </v-card>
            </v-col>
            <v-divider vertical/>
            <v-col cols="2">
              <v-card flat class="sticky">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <div>
                        <p class="mt-5">{{ $store.getters.translate("fields") }}</p>
                        <v-text-field
                            class="ma-0"
                            v-model="search"
                            :label="$store.getters.translate('search')"
                            flat
                            hide-details
                            clearable
                            clear-icon="mdi-close-circle-outline"
                            prepend-icon="mdi-magnify"/>
                        <v-treeview
                          :search="search"
                          transition
                          item-key="name"
                          dense
                          :items="template_fields"
                          item-text="name">
                          <template v-slot:label="{ item }">
                            <a @click="insertContent(item)">{{ $store.getters.translate(item.name) }}</a>
                          </template>
                        </v-treeview>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-card flat style="height: 100%">
            <v-card-text style="height: 100%">
              <v-layout v-if="!preview_url" align-center justify-center column fill-height>
                <v-flex row align-center>
                  <div class="justify-center">
                    <v-progress-circular :size="100" color="primary" indeterminate/>
                    <transition name="fade">
                      <h3 class="mt-5">{{ $store.getters.translate("loading") }}</h3>
                    </transition>
                  </div>
                </v-flex>
              </v-layout>
              <p v-else align="center" style="height: 100%" class="mt-5">
                <iframe :key="key" style="overflow: hidden; height: 1000px; width: 100%" height="100%" width="800px" :src="preview_url" frameborder="0"/>
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat style="height: 100%">
            <v-card-text style="height: 100%">
              <v-layout v-if="!preview_url" align-center justify-center column fill-height>
                <v-flex row align-center>
                  <div class="justify-center">
                    <v-progress-circular :size="100" color="primary" indeterminate/>
                    <transition name="fade">
                      <h3 class="mt-5">{{ $store.getters.translate("loading") }}</h3>
                    </transition>
                  </div>
                </v-flex>
              </v-layout>
              <p v-else align="center" style="height: 100%" class="mt-5">
                <iframe :key="key" style="overflow: hidden; height: 1000px; width: 100%" height="100%" width="800px" :src="preview_url" frameborder="0"/>
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import BaseEditor from "../../components/commonComponents/BaseEditor";
import BaseLogField from "../../components/commonComponents/BaseLogField.vue";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseLogField,
    BaseBreadcrumb,
    BaseModal,
    BaseDraggableModal,
    BaseEditor
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("templates"),
        name: "templates",
        model: "template",
      },
      record: {
        name: "",
        html_nl: "",
        html_en: "",
        margin_bottom: 0,
        margin_top: 0,
        margin_left: 0,
        margin_right: 0,
      },
      template_fields: [
        {
          name: "Certificate",
          children: [
            {
              model_type: "Certificate",
              name: "issueing_date"
            },{
              model_type: "Certificate",
              name: "valid_until"
            }
          ]
        },
        {
          name: "Scheme",
          children: [
            {
              model_type: "Scheme",
              name: "name"
            },{
              model_type: "Scheme",
              name: "description"
            },{
              model_type: "Scheme",
              name: "section_name"
            },{
              model_type: "Scheme",
              name: "section_number"
            }
          ]
        },
        {
          name: "Training",
          children: [
            {
              model_type: "Training",
              name: "name"
            },{
              model_type: "Training",
              name: "description"
            },{
              model_type: "Training",
              name: "start_date"
            },{
              model_type: "Training",
              name: "end_date"
            }
          ]
        },
        {
          name: "Worker",
          children: [
            {
              model_type: "Person",
              name: "initials"
            },{
              model_type: "Person",
              name: "first_name"
            },{
              model_type: "Person",
              name: "insertion"
            },{
              model_type: "Person",
              name: "last_name"
            },{
              model_type: "Person",
              name: "date_of_birth"
            },{
              model_type: "Person",
              name: "phonenumber"
            },{
              model_type: "Person",
              name: "mobilenumber"
            },{
              model_type: "Person",
              name: "billingnumber"
            },{
              model_type: "Person",
              name: "gender"
            },{
              model_type: "Person",
              name: "place_of_birth"
            },{
              model_type: "Person",
              name: "email"
            }
          ]
        },
      ],
      usual_fields: [],
      tags_required: false,
      loading: false,
      search: null,
      file: null,
      fields: [],
      key: 0,
      preview_url: null,
      language: 'nl',
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, this.$route.params.id);
      await this.getFields();
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.hideHeader();
          this.hideFooter();
          this.loading = true;
          if(await helpFunctions.saveModel(this.$toasted, this.page.name, this.record)) {
            await this.load();
          }
          this.loading = false;
        }
      }
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            await this.$router.push("/" + this.page.name);
          }
          this.loading = false;
        }
      });
    },
    saveImage() {
      const formData = new FormData();
      formData.append("background_image", this.file, "name.jpeg");
      this.$http
        .post(this.$store.getters.appUrl + "v2/templates/file?id=" + this.record.id, formData, { headers: { "Content-Type": "multipart/form-data" } })
        .then(() => {
          this.load();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteImage() {
      this.$http
        .post(this.$store.getters.appUrl + "v2/templates/file/delete?id=" + this.record.id)
        .then(() => {
          this.load();
          this.file = null;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setLanguage(language) {
      this.language = language;
    },
    preview(language) {
      this.loading = true;
      this.record.language = language;
      this.$http
        .post(this.$store.getters.appUrl + "v2/pdf/preview", this.record)
        .then((response) => {
          this.record.language = null;
          this.loading = false;
          this.key = this.key + 1;
          this.preview_url = response.data;
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    insertContent(item) {
      if (item.name) {
        let model_type = item.model_type.toLowerCase() + ".";
        let source = item.source ? item.source.toLowerCase() + "." : "";
        let name = item.name.toLowerCase();
        if (this.language === 'en') {
          this.$refs.rte_main_en.$refs.text_editor.editor.insertContent("${" + model_type + source + name + "}");
        }
        else {
          this.$refs.rte_main_nl.$refs.text_editor.editor.insertContent("${" + model_type + source + name + "}");
        }
      }
    },
    showHeader() {
      this.$refs.modal_header.open();
    },
    showFooter() {
      this.$refs.modal_footer.open();
    },
    hideHeader() {
      this.$refs.modal_header.close();
    },
    hideFooter() {
      this.$refs.modal_footer.close();
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.loading = false;
    },
    canEdit() {
      return this.$can("edit", this.page.model);
    },
    canDelete() {
      return this.$can("delete", this.page.model);
    },
  },
  computed: {
    margins() {
      return {
        margin_top: this.record.margin_top,
        margin_bottom: this.record.margin_bottom,
        margin_left: this.record.margin_left,
        margin_right: this.record.margin_right,
      };
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 30px;
}
</style>